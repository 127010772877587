@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";

$primary: #73A24E;
$secondary: #A9C25D;
$body-bg: #F5EEC2;

$theme-colors: (
        primary: $primary,
        secondary: $secondary
);

@import "../node_modules/bootstrap/scss/bootstrap";

$btn-transition: all 0.4s;

:root {
  --fc-border-color:  #b3b3b3;
}

#topbar .nav-link {
  position: relative;
}

#topbar .nav-link:after {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 0;
  content: ".";
  color: transparent;
  background: #FFFFFF;
  height: 2px;
}

@media screen and (min-width:992px) {
  #topbar .nav-link:hover:after,
  #topbar .nav-link.active:after {
    width: 85%;
  }

  #topbar .nav-link,
  #topbar .nav-link:after,
  #topbar .nav-link:before {
    transition: all .5s;
  }
}

a.fc-daygrid-day-number {
  text-decoration: none;
  color: $body-color;
}

a.fc-col-header-cell-cushion {
  color: $body-color;
}

.fc-event-main-frame {
  flex-direction: column;
}

.fc-daygrid-event .fc-event-time {
  font-size: var(--fc-small-font-size);
  margin-bottom: 1px;
  white-space: nowrap;
  flex-grow: 0;
  flex-shrink: 0;
  max-height: 100%;
  overflow: hidden;
}

@media screen and (max-width:767px) { .fc-toolbar.fc-header-toolbar {font-size: 80%}}
@media screen and (max-width:575px) { .fc-toolbar.fc-header-toolbar {font-size: 50%}}

.contact-wrap {
  background: #fff;
}
.info-wrap h3 {
  color: #fff;
}
.info-wrap .dbox {
  width: 100%;
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 25px;
}
.info-wrap .dbox:last-child {
  margin-bottom: 0;
}
.info-wrap .dbox p {
  margin-bottom: 0;
}
.info-wrap .dbox p span {
  font-weight: 600;
  color: #fff;
}
.info-wrap .dbox p a {
  color: #fff;
}
.info-wrap .dbox .icon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.1);
}
.info-wrap .dbox .icon span {
  font-size: 20px;
  color: #fff;
}
.info-wrap .dbox .text {
  width: calc(100% - 50px);
}

@media (min-width: 992px) {
  .info-wrap {
    margin-top: -20px;
    margin-bottom: -20px;
    border-radius: 5px;
  }
}

body {
  min-width: 330px;
}

#mainLogoReg:hover {
  animation: shake 1.3s;
  animation-iteration-count: infinite;
}

@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}

.card-hover {
  overflow: hidden;
  position: relative;
  padding-bottom: 60%;
}

.card-hover-overlay {
  width: 100%;
  height: 99%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 90;
  transition: all 0.4s;
  background: rgba(0, 0, 0, 0.5);
}

.card-hover:hover .card-hover-overlay {
  opacity: 0;
}

.card-hover img {
  width: 105%;
  position: absolute;
  top: 0;
  left: -5%;
  transition: all 0.3s;
}

.card-hover:hover img {
  left: 0;
}

.card-hover-content {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 99;
  transition: all 0.4s;
}

.card-hover:hover .card-hover-content {
  bottom: 2rem;
}

.card-hover-description {
  transform: translateY(0.5rem);
  transition: all 0.4s;
  opacity: 0;
}

.card-hover:hover .card-hover-description {
  opacity: 1;
  transform: none;
}

.card-hover-button {
  transform: translateY(0.5rem);
  color: white;
  opacity: 0;
}

.card-hover:hover .card-hover-button {
  opacity: 1;
  transform: none;
}

section {
  padding: 60px 0;
  overflow: hidden;
}

.section-title {
  text-align: center;
  padding-bottom: 40px;
}

.section-title h2 {
  color: var(--bs-body-color);
  font-size: 32px;
  font-weight: 700;
  position: relative;
}

.section-title h2:before,
.section-title h2:after {
  content: "";
  width: 50px;
  height: 2px;
  background: $primary;
  display: inline-block;
}

.section-title h2:before {
  margin: 0 15px 10px 0;
}

.section-title h2:after {
  margin: 0 0 10px 15px;
}

.section-title p {
  margin-bottom: 0;
}

.features .features-item {
  color: rgba(var(--bs-body-color), 0.8);
}

.features .features-item+.features-item {
  margin-top: 45px;
}

@media (max-width: 768px) {
  .features .features-item+.features-item {
    margin-top: 40px;
  }
}

.features .features-item h3 {
  color: white;
  font-weight: 700;
  font-size: 26px;
}

.features .features-item ul {
  list-style: none;
  padding: 0;
}

.features .features-item ul li {
  padding-bottom: 10px;
  display: flex;
  align-items: flex-start;
}

.features .features-item ul li:last-child {
  padding-bottom: 0;
}

.features .features-item ul i {
  font-size: 20px;
  padding-right: 4px;
  color: $primary;
}

.features .features-item img {
  border: 6px solid white;
  box-shadow: 0 15px 30px -10px rgba(0, 0, 0, 0.1);
}

.features .features-item .features-img-bg {
  position: relative;
  min-height: 500px;
}

@media (max-width: 640px) {
  .features .features-item .features-img-bg {
    min-height: 300px;
  }
}

.features .features-item .features-img-bg img {
  position: absolute;
  inset: 0;
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.features .features-item .image-stack {
  display: grid;
  position: relative;
  grid-template-columns: repeat(12, 1fr);
}

.features .features-item .image-stack .stack-back {
  grid-column: 4/-1;
  grid-row: 1;
  width: 100%;
  z-index: 1;
}

.features .features-item .image-stack .stack-front {
  grid-row: 1;
  grid-column: 1/span 8;
  margin-top: 20%;
  width: 100%;
  z-index: 2;
}

.features .features-item .image-stack .stack-back:hover {
  z-index: 3;
}

.hvr-grow-shadow {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: box-shadow, transform;
  transition-property: box-shadow, transform;
}
.hvr-grow-shadow:hover, .hvr-grow-shadow:focus, .hvr-grow-shadow:active {
  box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.5);
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.portfolio .portfolio-item {
  margin-bottom: 30px;
}

.portfolio #portfolio-flters {
  padding: 0;
  margin: 0 auto 20px auto;
  list-style: none;
  text-align: center;
}

.portfolio #portfolio-flters li {
  cursor: pointer;
  display: inline-block;
  padding: 8px 15px 10px 15px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  text-transform: uppercase;
  color: var(--bs-body-color);
  margin-bottom: 5px;
  transition: all 0.3s ease-in-out;
  border-radius: 3px;
}

.portfolio #portfolio-flters li:hover,
.portfolio #portfolio-flters li.filter-active {
  color: white;
  background: $primary;
}

.portfolio #portfolio-flters li:last-child {
  margin-right: 0;
}

.portfolio .portfolio-wrap {
  transition: 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  background: rgba(21, 21, 21, 0.6);
}

.portfolio .portfolio-wrap::before {
  content: "";
  background: rgba(21, 21, 21, 0.6);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  transition: all ease-in-out 0.3s;
  z-index: 2;
  opacity: 0;
}

.portfolio .portfolio-wrap img {
  transition: all ease-in-out 0.3s;
}

.portfolio .portfolio-wrap .portfolio-info {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  transition: all ease-in-out 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 20px;
}

.portfolio .portfolio-wrap .portfolio-info h4 {
  font-size: 20px;
  color: #fff;
  font-weight: 600;
}

.portfolio .portfolio-wrap .portfolio-info p {
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  text-transform: uppercase;
  padding: 0;
  margin: 0;
  font-style: italic;
}

.portfolio .portfolio-wrap .portfolio-links {
  text-align: center;
  z-index: 4;
}

.portfolio .portfolio-wrap .portfolio-links svg {
  color: #fff;
  margin: 0 5px 0 0;
  font-size: 28px;
  display: inline-block;
  transition: 0.3s;
}

.portfolio .portfolio-wrap .portfolio-links svg:hover {
  color: $primary;
}

.portfolio .portfolio-wrap:hover::before {
  opacity: 1;
}

.portfolio .portfolio-wrap:hover img {
  transform: scale(1.2);
}

.portfolio .portfolio-wrap:hover .portfolio-info {
  opacity: 1;
}

.feedbacks .feedback-slider {
  overflow: hidden;
}

.feedbacks .feedback-item {
  box-sizing: content-box;
  min-height: 320px;
}

.feedbacks .feedback-item .feedback-img {
  width: 90px;
  border-radius: 50%;
  margin: -40px 0 0 40px;
  position: relative;
  z-index: 2;
  border: 6px solid #fff;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
}

.feedbacks .feedback-item h3 {
  font-size: 18px;
  font-weight: bold;
  margin: 10px 0 5px 45px;
  color: #2c4964;
}

.feedbacks .feedback-item h4 {
  font-size: 14px;
  color: #999;
  margin: 0 0 0 45px;
}

.feedbacks .feedback-item .feedback-icon-left,
.feedbacks .feedback-item .feedback-icon-right {
  color: var(--bs-body-color);
  font-size: 26px;
}

.feedbacks .feedback-item .feedback-icon-left {
  display: inline-block;
  left: -5px;
  position: relative;
}

.feedbacks .feedback-item .feedback-icon-right {
  display: inline-block;
  right: -5px;
  position: relative;
  top: 10px;
}

.feedbacks .feedback-item p {
  font-style: italic;
  margin: 0 15px 0 15px;
  padding: 20px 20px 60px 20px;
  background: #fff;
  position: relative;
  border-radius: 6px;
  z-index: 1;
}

.feedbacks .swiper-pagination {
  margin-top: 20px;
  position: relative;
}

.feedbacks .swiper-pagination .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
}

.feedbacks .swiper-pagination .swiper-pagination-bullet-active {
  background-color: $primary;
}

@media (max-width: 767px) {
  .feedbacks {
    margin: 30px 10px;
  }
}

.supporterCard h4 a {
  color: $primary;
}

.supporterCard:hover {
  transform: translateY(-10px);
}

.supporterCard:hover h4 a {
  color: $secondary;
}

.supporterdetails .nav {
  border: 0;
}

.supporterdetails .nav-link {
  border: 0;
  padding: 25px 20px;
  color: white;
  border-radius: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: 0s;
  cursor: pointer;
  height: 100%;
  word-break: break-word;
  background-color: $primary;
}

.supporterdetails .nav-link p {
  font-size: 18px;
  font-weight: 450;
  margin: 10px 0 0 0;
  color: white;
}

.supporterdetails .nav-link:hover {
  background-color: $secondary;
}

.supporterdetails .nav-link:hover p {
  color: white
}

.supporterdetails .nav-link.active {
  transition: 0.3s;
  background: var(--bs-body-bg);
  border-color: $primary;
}

.supporterdetails .nav-link.active p {
  color: var(--bs-body-color);
}

.supporterdetails .tab-content {
  margin-top: 30px;
}

.supporterdetails .tab-pane.active {
  animation: 0.45s ease-out 0s 1 fadeIn;
}

@keyframes fadeIn {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}